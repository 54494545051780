import axios from 'axios';
import notify from '@/utils/notify';
import router from '@/router';
import Vue from 'vue';
import { getCookie } from '@/utils/cookieUtils';

function sendLogs(ddLogger, { response, route, config, message }) {
  try {
    const journey =
      ddLogger.journey || route.meta?.name || route.name || route.path;

    const logResponse = ddLogger
      .setMessage(message)
      .setResponse(response)
      .setConfig(config)
      .setRoute(route)
      .setName(journey)
      .sendLogs();

    if (logResponse.error) {
      console.error(logResponse.message);
    }
  } catch (ex) {
    console.error(ex.message);
  }
}

const instance = axios.create({
  baseURL: process.env.VUE_APP_API,
  withCredentials: false,
});

instance.interceptors.request.use(
  function (config) {
    const apiKey = getCookie('api_key');
    config.headers.apikey = apiKey ?? '';
    config.metadata = { startTime: new Date().getTime() };

    // override base url if mock is enabled and should work only for development
    if (
      config.useMock &&
      process.env.VUE_APP_MOCK_API &&
      process.env.NODE_ENV !== 'production'
    ) {
      config.baseURL = process.env.VUE_APP_MOCK_API;
      config.withCredentials = false;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    sendLogs(Vue.prototype.$ddLogger, {
      route: router.currentRoute,
      response,
      config: response.config,
      message: response?.statusText || 'server response success',
    });
    return response;
  },
  function (error) {
    sendLogs(Vue.prototype.$ddLogger, {
      route: router.currentRoute,
      response: error.response,
      config: error.config,
      message: error?.message || 'client response error',
    });
    if (error?.response.status === 401) {
      const errors = error?.response?.data?.errors || [];

      errors.forEach((error) => {
        notify('danger', 'Error', error);
      });
    } else if (error?.response.status === 422) {
      const warnings = Object.values(error?.response?.data) || [];
      const total = warnings.length;

      if (warnings.length > 1) {
        notify('warning', 'Warning', `${warnings[0]} and (${total} more)...`);
      } else {
        notify('warning', 'Warning', `${warnings[0]}`);
      }
    } else {
      notify('danger', 'Error', 'Something went wrong');
    }

    return Promise.reject(error);
  }
);

export default instance;
